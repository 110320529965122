/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

:host {
  display: block;
}

.dispatch__card {
  border-radius: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  ion-card-title {
    font-size: 18px;
  }
  ion-card-subtitle {
    padding-left: 22px;
    padding-top: 5px;
    text-transform: none;
  }

  .dispatch__status {
    position: absolute;
    right: 15px;
    top: 10px;
  }
}

.stepper-wrapper {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  min-height: 40px;

  margin-left: -34px;
  margin-right: -34px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .step-name {
    display: none;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }

  &.active {
    font-weight: bold;

    .step-counter {
      width: 40px;
      height: 40px;
      top: -14px;

      ion-icon {
        display: inline-flex !important;
      }
    }
  }

  &.completed .step-counter,
  &.active .step-counter {
    background-color: var(--ion-color-primary);
  }
}

.stepper-item::before {
  position: absolute;
  content: '';
  //border-bottom: 3px solid var(--ion-color-light-shade);
  background-color: var(--ion-color-light-shade);
  height: 2px;
  width: 100%;
  top: 4px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: '';
  //border-bottom: 3px solid var(--ion-color-light-shade);
  background-color: var(--ion-color-light-shade);
  height: 2px;
  width: 100%;
  top: 4px;
  left: 50%;
  z-index: 2;
}

.stepper-item:first-child {
  &.active::after {
    background-color: var(--ion-color-light-shade);
  }
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--ion-color-light-shade);
  margin-bottom: 6px;
  outline: 3px solid white;

  font-size: 18px;
  color: white;

  ion-icon {
    display: none;
  }
}

.stepper-item.completed::after {
  position: absolute;
  content: '';
  //border-bottom: 3px solid #4bb543;
  background-color: var(--ion-color-primary);
  height: 2px;
  width: 100%;
  top: 4px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.route-wrapper {
  display: flex;
  justify-content: space-between;

  .route {
    font-weight: 500;

    &.route_pickup {
      text-align: left;
      width: 50%;
    }
    &.route_delivery {
      text-align: right;
      width: 50%;
    }

    &__date {
      text-transform: capitalize;
      color: var(--ion-color-medium);
    }

    &__location {
      color: var(--ion-color-dark);
      margin-top: 2px;
    }
  }
}
